var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('div', {
    staticClass: "auth-inner"
  }, [_c('router-link', {
    staticClass: "brand-logo d-flex align-center",
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    staticClass: "me-3 ",
    attrs: {
      "src": _vm.appLogo,
      "max-height": "30px",
      "max-width": "30px",
      "alt": "logo",
      "contain": ""
    }
  }), _c('h2', {
    staticClass: "text--primary"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])], 1), _c('v-row', {
    staticClass: "auth-row ma-0"
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block position-relative overflow-hidden pa-0",
    attrs: {
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "auth-illustrator-wrapper"
  }, [_c('img', {
    staticClass: "auth-mask-bg",
    attrs: {
      "height": "362",
      "src": require("@/static/images/misc/mask-v2-".concat(_vm.$vuetify.theme.dark ? 'dark' : 'light', ".png"))
    }
  }), _c('v-img', {
    staticClass: "auth-tree",
    attrs: {
      "height": "226",
      "width": "300",
      "src": require("@/static/images/misc/tree-4.png")
    }
  }), _c('div', {
    staticClass: "d-flex align-center h-full pa-16 pe-0"
  }, [_c('v-img', {
    staticClass: "auth-3d-group",
    attrs: {
      "contain": "",
      "max-width": "100%",
      "height": "692",
      "src": require("@/static/images/3d-characters/group-".concat(_vm.$vuetify.theme.dark ? 'dark' : 'light', ".png"))
    }
  })], 1)], 1)]), _c('v-col', {
    staticClass: "d-flex align-center auth-bg pa-10 pb-0",
    attrs: {
      "lg": "4"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('p', {
    staticClass: "text-2xl font-weight-semibold text--primary mb-2"
  }, [_vm._v(" Channel Admin Portal ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Please sign-in to your account ")])]), _c('v-card-text', [_c('v-form', {
    ref: "loginForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleFormSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    staticClass: "mb-6",
    attrs: {
      "outlined": "",
      "label": "Email",
      "placeholder": "email",
      "error-messages": _vm.errorMessages.email,
      "rules": [_vm.validators.required, _vm.validators.emailValidator],
      "hide-details": "auto"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('v-text-field', {
    staticClass: "mb-2",
    attrs: {
      "outlined": "",
      "type": _vm.isPasswordVisible ? 'text' : 'password',
      "label": "Password",
      "error-messages": _vm.errorMessages.password,
      "placeholder": "Password",
      "append-icon": _vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,
      "rules": [_vm.validators.required],
      "hide-details": "auto"
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.isPasswordVisible = !_vm.isPasswordVisible;
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorMessages.system !== null,
      expression: "errorMessages.system !== null"
    }],
    staticClass: "d-flex align-center justify-space-between flex-wrap"
  }, [_c('div', {
    staticClass: "font-weight-semibold mt-6 error-message"
  }, [_vm._v(" " + _vm._s(_vm.errorMessages.system) + " ")])]), _c('v-btn', {
    staticClass: "mt-6",
    attrs: {
      "block": "",
      "color": "primary",
      "type": "submit",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Login ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }